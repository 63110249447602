import React from "react"
import Categories from "./Category"
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function Scoresheet(props) {
    const navigate = useNavigate();

    const location = useLocation();

    // If no categories are met, set all unfilled categories to met
    const noMatches = props.categories.filter(category => !category.isFilled)
    const isMet = props.categories.some(category => category.isMet)
    let tempCategories = [];
    let category;

    if (props.endTurn && noMatches.length > 0 && !isMet) {
        // No rolls left 
        for (let i = 0; i < props.categories.length; i++) {
            category = props.categories[i]
            // Category is filled - copy as is
            if (category.isFilled) {
                tempCategories.push(category)
            } else {
                // Category is unfilled set is true and zero value
                if (category.category === "Spare Change") {
                    tempCategories.push({ ...category, isMet: true })
                } else {
                    tempCategories.push({ ...category, isMet: true, value: 0 })
                }
            }
        }
    } else {
        // We still have rolls left - normal play of game - simply build tempCategories
        for (let i = 0; i < props.categories.length; i++) {
            category = props.categories[i]
            tempCategories.push(category)
        }
    }

    const scoresheetElements = tempCategories.map(category => (
        <Categories
            key={category.id}
            category={category.category}
            value={category.isMet || category.isFilled ? category.value : 0}
            isMet={category.isMet}
            isFilled={category.isFilled}
            selectCategory={() => selectCategory(category.id, category.value)}
            bank={location.state.path}
        />
    ))

    // Updated the selected category and clear isMet on all categories
    function selectCategory(id, value) {
        let item = props.categories.find(item => item.id === id);
        let someCategoryMet = props.categories.find(item => item.isMet === true);

        // Check that we didn't choose a previous filled category
        if (item.isFilled) {
            alert("Please select an unfilled category")
        } else {
            // Is isMet true for the selected item or are no categories met? 
            if (item.isMet || !someCategoryMet) {
                // If we are filling in a blank category clear the value (score)
                if (!item.isMet) {
                    value = 0
                    item.value = 0
                }

                // Yes - process the item
                props.setCategories(oldCategory => oldCategory.map(category => {
                    return category.id === id ?
                        { ...category, isFilled: true, isMet: false, value: value } :
                        { ...category, isMet: false }
                }))

                // Accumulate total score
                props.setTotalScore(oldTotalScore => oldTotalScore + item.value)

                //Set end turn to false
                props.setEndTurn(false)

                // Nav back to game
                navigate("/")
            } else {
                // No - are any items met
                alert("Please select a category with a value")
            }
        }


    }

    function toGame() {
        navigate("/")
    }

    return (
        <main>
            <div className="scoresheet">
                <h1 className="title">Scoresheet</h1>
                <table>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scoresheetElements}
                    </tbody>
                </table>
            </div>
                {!location.state.path &&<button
                    className="button-format"
                    onClick={toGame}
                >
                    Game
                    </button>}

        </main>
    )
}
