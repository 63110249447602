import React from "react"
import Die from "./Die"
import Dice from "../data/Dice"
import Categories from "./Category"
import Category from "../data/Categories"
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';

export default function Game(props) {
    const navigate = useNavigate();
    const [endGame, setEndGame] = React.useState(false)
    const [notBankable, setNotBankable] = React.useState(true)
    const [processBottom, setProcessBottom] = React.useState(false)
    const [showConfetti, setShowConfetti] = React.useState(false);

    const navigateToScoresheet = (bank) => {
        navigate('/scoresheet/', { state: { path: bank} } );
    };

    // This is called after dice changes (rollDice)
    React.useEffect(() => {

        const allScored = props.categories.every(category => category.value > 0)
        const allFilled = props.categories.every(category => category.isFilled)

        // All the categories are filled - end of game
        if (allFilled) {
            setEndGame(true)

            if (allScored) {
                alert("Congratulations on completing all the categories")
                setShowConfetti(true)
            }

            if (props.totalScore >= 400) {
                alert("Woohoo, not everyone scores 400")
                setShowConfetti(true)
            }

            // Store the new high score 
            if (props.totalScore > props.highScore) {
                localStorage.setItem('bankrollHighScore', JSON.stringify(props.totalScore));
                alert("Congratulations, you set a new high score")
                setShowConfetti(true)
            }            
        }

        // Set end turn if there are no more rolls
        if (props.rolls === 0 && props.bankedRolls === 0) {
            props.setEndTurn(true)
        }

        // Enable banking 
        const isMet = props.categories.some(category => category.isMet)
        setNotBankable(true)
        if (isMet === true) {
            setNotBankable(false)
        }
        if ((props.rolls === 0 && props.bankedRolls === 0) && isMet === false) {
            setNotBankable(false)
        }

        // Top is complete?
        const result = props.categories.filter((category) => category.isTop === true && category.isFilled === true);
        if (result.length === 7) {
          setProcessBottom(true)
        }

    }, [props])

    // Set high score
    React.useEffect(() => {
        const highScore = JSON.parse(localStorage.getItem('bankrollHighScore'));
        if (highScore) {
            props.setHighScore(highScore);
        }
    }, [props]);

    function rollDice() {
        // Decrement the number of rolls
        if (props.rolls > 0) {
            props.setRolls(oldRoll => oldRoll = oldRoll - 1)
        } else if (props.bankedRolls > 0) {
            props.setBankedRolls(oldBankedRoll => oldBankedRoll = oldBankedRoll - 1)
        }

        // Roll the unselected dice - store the dice in a temporary variable
        // We update state from this and can use the values in evaluateDice
        // This avoids creating state dependencies
        const tempDice = [];
        for (let i = 0; i < props.dice.length; i++) {
            const dice = props.dice[i]
            if (dice.isHeld) {
                tempDice.push(dice)
            } else {
                tempDice.push({ ...dice, value: Math.ceil(Math.random() * 6) })
            }
        }

        // Highlight matching dice if we are processing the bottom
        if (processBottom) {
            const redDice = tempDice.find(dice => dice.isRed)
            for (let i = 0; i < tempDice.length; i++) {
                const dice = tempDice[i]
                if (dice.isRed === false && dice.value === redDice.value &&
                    dice.isHeld === false && redDice.isHeld === true) {
                    tempDice[i].isHeld = true;
                } 
            }
        }

        // Set the dice array
        props.setDice(tempDice)

        // Evaluate the dice
        evaluateDice(tempDice, props.setCategories)
    }

    function bankDice() {

        // Set banked rolls
        props.setBankedRolls(oldBanks => oldBanks + props.rolls)

        // Call scoresheet tab - enabling banking
        let bank = true
        navigateToScoresheet(bank)

        // Reset dice
        props.setDice(Dice)
        props.setRolls(3)
    }

    function toScoresheet() {
        // Call scoresheet tab - disabling banking
        let bank = false
        navigateToScoresheet(bank)
    }

    function newGame() {
        // Reset all the variables
        // Global variables
        props.setDice(Dice)
        props.setEndTurn(false)
        props.setRolls(3)
        props.setTotalScore(0)
        props.setBankedRolls(0)
        props.setCategories(Category)

        // Local varaiables
        setProcessBottom(false)
        setShowConfetti(false)
        setEndGame(false)
    }

    function holdDice(id) {
        props.setDice(oldDice => oldDice.map(die => {
            return die.id === id ?
                { ...die, isHeld: !die.isHeld } :
                die
        }))
    }

    const diceElements = props.dice.map(die => (
        <Die
            key={die.id}
            value={die.value}
            isHeld={die.isHeld}
            isRed={die.isRed}
            isMatch={die.isMatch}
            holdDice={() => holdDice(die.id)}
        />
    ))

    const scoresheetElements = props.categories.map(filterCategories)
    function filterCategories(category) {
        if (category.isMet) {
            return (
                <Categories
                    key={category.id}
                    category={category.category}
                    value={category.value}
                    isMet={category.isMet}
                />
            )
        }
    }

    const styles = {
        backgroundColor: props.endTurn ? "#F0EAD6" : "#5035FF"
    }

    const bankStyles = {
        backgroundColor: notBankable ? "#F0EAD6" : "#5035FF"
    }

    return (
        <main>
            {showConfetti && <Confetti />}
            <h1 className="title">Bank Roll</h1>
                <div className="total-container">
                    <div className="total-label">
                        Rolls
                    </div>
                    <div className="total-value">
                        {props.rolls}
                    </div>
                    <div className="total-label">
                        Banked
                    </div>
                    <div className="total-value">
                        {props.bankedRolls}
                    </div>
                    <div className="total-label">
                        Score
                    </div>
                    <div className="total-score">
                        {props.totalScore}
                    </div>
                    <div className="total-label">
                        High
                    </div>
                    <div className="total-score">
                        {props.highScore}
                    </div>
                </div>
            <div className="dice-container">
                {diceElements}
            </div>
            <div className="button-container">
                {!endGame && <button
                    disabled={props.endTurn}
                    style={styles}
                    className="button-format"
                    onClick={rollDice}
                >
                    Roll
                </button>}
                {!endGame && <button
                    disabled={notBankable}
                    style={bankStyles}
                    className="button-format"
                    onClick={bankDice}
                >
                    Bank
                </button>}
                {!endGame && <button
                    className="button-format"
                    onClick={toScoresheet}
                >
                    Scoresheet
                </button>}
            </div>
            <div className="button-container-end">
                {endGame && <button
                    className="button-format"
                    onClick={newGame}
                >
                    New Game
                </button>}
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {scoresheetElements}
                </tbody>
            </table>
        </main>
    )
}

function evaluateDice(inDice, inSetCategory) {
    // Initialize categories
    let threePair = false,
        twoTrips = false,
        evens = false,
        odds = false,
        lows = false,
        highs = false,
        straight = false,
        threeKind = false,
        fourKind = false,
        fiveKind = false,
        sixKind = false,
        sevenKind = false,
        isRed = false;

    // Totals for summed categories
    let sixKindScore = 0,
        fiveKindScore = 0,
        fourKindScore = 0,
        threeKindScore = 0,
        spareChangeScore = 0;

    // Clear counters
    let allLows = 0,
        allHighs = 0,
        allEvens = 0,
        allOdds = 0;

    // Evaluate the dice - how many of each face value
    let diceResults = [0, 0, 0, 0, 0, 0, 0]

    for (let i = 0; i < inDice.length; i++) {
        let value = inDice[i].value
        switch (value) {
            case 1:
                diceResults[0]++;
                break;
            case 2:
                diceResults[1]++;
                break;
            case 3:
                diceResults[2]++;
                break;
            case 4:
                diceResults[3]++;
                break;
            case 5:
                diceResults[4]++;
                break;
            case 6:
                diceResults[5]++;
                break;
            default:
                break;
        }
    }

    // Kinds
    for (let i = 0; i < diceResults.length; i++) {
        if (diceResults[i] === 7) {
            sevenKind = true;
        }
        if (diceResults[i] >= 6) {
            sixKindScore = calculateValue(i + 1, 6, inDice)
            sixKind = isRedFixed(i + 1, inDice);
        }
        if (diceResults[i] >= 5) {
            fiveKindScore = calculateValue(i + 1, 5, inDice)
            fiveKind = isRedFixed(i + 1, inDice);
        }
        if (diceResults[i] >= 4) {
            fourKindScore = calculateValue(i + 1, 4, inDice)
            fourKind = isRedFixed(i + 1, inDice);
        }
        if (diceResults[i] >= 3) {
            threeKindScore = calculateValue(i + 1, 3, inDice)
            threeKind = isRedFixed(i + 1, inDice);
        }
    }

    // Three pair
    threePair = false;
    for (let i = 0; i < diceResults.length; i++) {
        if (diceResults[i] >= 2) {
            for (let j = i + 1; j < diceResults.length; j++) {
                if (diceResults[j] >= 2) {
                    for (let h = j + 1; h < diceResults.length; h++) {
                        if (diceResults[h] >= 2) {
                            threePair = isRedFixed(i + 1, inDice);
                            if (threePair === false) {
                                threePair = isRedFixed(j + 1, inDice);
                                if (threePair === false) {
                                    threePair = isRedFixed(h + 1, inDice);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // Logic for 4 + 2 pairs combination
    if (threePair === false) {
        let existsFour = diceResults.find(item => item >= 4);
        let existsTwo = diceResults.find(item => item >= 2 && item <= 3);
        if (existsFour !== undefined && existsTwo !== undefined) {
            for (let i = 0; i < diceResults.length; i++) {
                if (diceResults[i] === existsFour) {
                    threePair = isRedFixed(i + 1, inDice);
                }
            }
            if (threePair === false) {
                for (let i = 0; i < diceResults.length; i++) {
                    if (diceResults[i] === existsTwo) {
                        threePair = isRedFixed(i + 1, inDice);
                    }
                }
            }
        }
    }

    // Two trips
    twoTrips = false;
    for (let i = 0; i < diceResults.length; i++) {
        if (diceResults[i] >= 3) {
            for (let j = i + 1; j < diceResults.length; j++) {
                if (diceResults[j] >= 3) {
                    twoTrips = isRedFixed(i + 1, inDice);
                    if (twoTrips === false) {
                        twoTrips = isRedFixed(j + 1, inDice);
                    }
                    break;
                }
            }
        }

    }

    // Two trips - six of a kind
    let existsSix = diceResults.find(item => item >= 6);
    if (existsSix !== undefined) {
        for (let i = 0; i < diceResults.length; i++) {
            if (diceResults[i] === existsSix) {
                twoTrips = isRedFixed(i + 1, inDice);
            }
        }
    }

    //Straight    
    if (diceResults[0] >= 1 &&
        diceResults[1] >= 1 &&
        diceResults[2] >= 1 &&
        diceResults[3] >= 1 &&
        diceResults[4] >= 1 &&
        diceResults[5] >= 1) {
        straight = true;
    }

    // Lows
    isRed = false;
    for (let i = 0; i < 3; i++) {
        allLows += diceResults[i];
        if (isRed === false) {
            isRed = isRedFixed(i + 1, inDice);
        }
    }
    if (allLows >= 6 && isRed === true) {
        lows = true;
    }

    // Highs
    isRed = false;
    for (let i = 3; i < 6; i++) {
        allHighs += diceResults[i];
        if (isRed === false) {
            isRed = isRedFixed(i + 1, inDice);
        }
    }
    if (allHighs >= 6 && isRed === true) {
        highs = true;
    }

    // Evens
    isRed = false;
    allEvens = diceResults[1] + diceResults[3] + diceResults[5];
    if (allEvens >= 6) {
        isRed = isRedFixed(2, inDice);
        if (isRed === false) {
            isRed = isRedFixed(4, inDice);
            if (isRed === false) {
                isRed = isRedFixed(6, inDice);
            }
        }
        if (isRed === true) {
            evens = true;
        }
    }

    // Odds
    isRed = false;
    allOdds = diceResults[0] + diceResults[2] + diceResults[4];
    if (allOdds >= 6) {
        isRed = isRedFixed(1, inDice);
        if (isRed === false) {
            isRed = isRedFixed(3, inDice);
            if (isRed === false) {
                isRed = isRedFixed(5, inDice);
            }
        }
        if (isRed === true) {
            odds = true;
        }
    }

    // Update cateory scoresheet
    // Three pairs
    setCategory(threePair, 1, 30, inSetCategory);

    // Two triples
    setCategory(twoTrips, 2, 30, inSetCategory);

    // Evens
    setCategory(evens, 3, 30, inSetCategory);

    // Odds
    setCategory(odds, 4, 30, inSetCategory);

    // Lows
    setCategory(lows, 5, 30, inSetCategory);

    // Highs
    setCategory(highs, 6, 30, inSetCategory);

    // Straight
    setCategory(straight, 7, 30, inSetCategory);

    // Three of a kind
    setCategory(threeKind, 8, threeKindScore, inSetCategory);

    // Four of a kind
    setCategory(fourKind, 9, fourKindScore, inSetCategory);

    // Five of a kind
    setCategory(fiveKind, 10, fiveKindScore, inSetCategory);

    // Six of a kind
    setCategory(sixKind, 11, sixKindScore, inSetCategory);

    // Seven of a kind
    setCategory(sevenKind, 12, 75, inSetCategory)

    // Spare Change
    spareChangeScore = diceResults[0] * 1 + diceResults[1] * 2 + diceResults[2] * 3
        + diceResults[3] * 4 + diceResults[4] * 5 + diceResults[5] * 6;
    setCategory(true, 13, spareChangeScore, inSetCategory);

}

function setCategory(isMet, id, score, inSetCategory) {
    inSetCategory(oldCategory => oldCategory.map(category => {
        return category.id === id && !category.isFilled ?
            { ...category, isMet: isMet, value: score } :
            category
    }))
}

function isRedFixed(faceValue, inDice) {
    let isRed = false
    let tempDice = inDice.find(item => item.value === faceValue && item.isRed === true);
    if (tempDice === undefined) {
        isRed = false
    } else {
        isRed = true
    }
    return isRed;
}

function calculateValue(faceValue, count, inDice) {
    let value = 0
    let tempDice = inDice.find(item => item.value === faceValue);
    if (tempDice === undefined) {
        value = 0
    } else {
        value = faceValue * count
    }
    return value;
}